import { useContext, useState, useEffect, useCallback, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

import { NodeSettingsContext } from "providers/NodeSettingsProvider";
import { createEss, getClientNodes } from "api/services/Clients";
import Card from "components/editor/Card";

const MAX_ESS_ID = 500;

function Assets() {
    const { clientId } = useContext(NodeSettingsContext);
    const queryClient = useQueryClient();
    const [dialogOpen, setDialogOpen] = useState(false);

    const { data, error, isLoading } = getClientNodes(clientId);

    const onEssCreationSuccess = () => {
        queryClient.invalidateQueries({ queryKey: ['clients', 'nodes'] });
    }

    const { mutate: mutateCreateEss, isPending } = createEss(clientId, onEssCreationSuccess);

    const [essId, setEssId] = useState(0);
    const [essName, setEssName] = useState("");

    /* Reset data on client change*/
    useEffect(() => {
        setEssId(0);
        setEssName("");
    }, [clientId])

    useEffect(() => {
        if (!isLoading && data) {
            for (let i = 1; i < MAX_ESS_ID; i++) {
                if (data.some((e: { nodeid: string }) => e.nodeid === `e${i}` || e.nodeid === `${i}`)) {
                    continue;
                }
                setEssId(i);
                return;
            }
            console.error("Reached max ESS ID without finding unused ID");
        }
    }, [data]);

    const onEssCreateSubmit = (ess_id: number, ess_name: string) => {
        mutateCreateEss({
            "ess_id": `${ess_id}`,
            "name": ess_name,
        }, { onSuccess: () => setDialogOpen(false) });
    };

    const handleSubmit = useCallback(() => onEssCreateSubmit(essId, essName), [essId, essName]);

    const Actions = useMemo(() => 
        <IconButton onClick={()=>setDialogOpen(true)}>
            <AddIcon />
        </IconButton>
    , []);

    return (
        <Card title={'Asset Management'} actions={Actions}>
            {(isLoading) && <CircularProgress />}
            {(!isLoading && !!error) && <span>Error loading client assets</span>}
            {(!isLoading && !error) && <>
                <span>Current assets:</span>
                {data.length == 0 && <><span> No current assets</span></>}
                {data.length > 0 && <ul>
                    {data.map((u: { node_name: string, nodeid: string }) => (
                        <li key={u.nodeid}>ID: <b>{u.nodeid}</b> / Name: <b>{u.node_name}</b></li>
                    ))}
                </ul>}
            </>}
            <Dialog open={dialogOpen} onClose={()=>setDialogOpen(false)}>
            <DialogTitle>Create new asset</DialogTitle>
            <DialogContent>
                <TextField label={"ID"} type={"number"} inputProps={{min:1}} value={essId} onChange={e => setEssId(parseInt(e.target.value))} />
                <TextField label={"Name"} type={"text"} inputProps={{minLength:3}} value={essName} onChange={e => setEssName(e.target.value)}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setDialogOpen(false)} variant={"contained"}>Cancel</Button>
                <Button type={"submit"} onClick={handleSubmit} disabled={isPending}>Submit</Button>
            </DialogActions>
        </Dialog>            
        </Card>)
}

export default Assets